$(function() {
  'use strict';
    if($('.js-modal').length > 0) {
      var modals = document.querySelectorAll(".js-modal");
      for (var index = 0; index < modals.length; index++) {
        var element = modals[index];
        var dialogeId = element.getAttribute("id");
        var dialogeElement = document.getElementById(dialogeId);

        var dialog = new A11yDialog(dialogeElement);
        dialog.on('show', function (event) {
          var container = event.target;
          var target = event.detail.target;
          var opener = target.closest('[data-a11y-dialog-show]');
          console.log(container, target, opener);
        });

        $(document).on("submit", "#" + dialogeId + " .c-form", function(e){
          e.preventDefault();

          var $form = $(e.target);

          $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
          })
          .done(function( data ) {
            var $newModal = $(data).find("#" + dialogeId);
            if ($newModal) {
              $("#" + dialogeId).html($newModal.html());
            }
          });
        });
      }

    }

});
